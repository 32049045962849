import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Modal from 'react-modal';

const rootElement = document.getElementById('root');
Modal.setAppElement(rootElement);
const root = ReactDOM.createRoot(rootElement);

/*root.render(<App />);*/
